* {
    scroll-behavior: smooth;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

#homePage {
    /* changed background using JS in Grid.jsx */

    padding: 0;
    margin: 0;
    transition: background-color 0.6s ease;

}

.wrapperForSectionOne {
    width: 100%;
    /* background-color: pink ; */
    display: flex;
    justify-content: center;
    align-items: center;

}

#sectionOne {

    height: 50vh;
    width: 100%;
    /* background: brown; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* flex-direction: row; */

    max-width: 1200px;
    max-height: 900px;
    margin: 50px auto 0 auto;
    /* border: 5px solid blue; */
    position: relative;
}

.sectionOneText {
    /* background-color: aqua ; */
    height: 100%;
    /* width: 100%; */
    font-size: 43px;
    position: absolute;
    left: 0px;
    top: 0;
    padding: 0 4%;
    display: flex;
    justify-content: start;
    align-items: center;

}

.sectionOneSvg {
    /* background-color: pink ; */
    height: 100%;
    display: flex;
    width: 100%;
    padding: 0 5%;
    justify-content: end;
    align-items: center;
}


.sectionOneSvg svg {
    height: 800px;
    width: 600px;
    margin-top: 150px;



}


@media (max-width: 900px) {

    #sectionOne {

        width: 100%;
        padding: 10px;
        align-items: start;
        justify-content: start;
        margin: 0 auto;
    }

    .sectionOneText {
        height: 100%;
        font-size: 30px;
        width: 90%;
        padding: 0 20px;
    }

    .sectionOneSvg {
        /* background-color: pink ; */
        height: 100%;
        display: flex;
        width: 100%;
        padding: 0 5%;
        justify-content: end;
        align-items: center;
    }


    .sectionOneSvg svg {
        height: 600px;
        width: 500px;
        margin-top: 150px;

    }

}


/* +============================= SECTION TWO */

.wrapperForSectionTwo {
    width: 100%;
    /* background-color: pink ; */
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: column;

}

#AaboutUsSection {
    height: 150px;

}

.container {
    width: 100vw;
    height: auto;
}

#sectionTwo {

    max-width: 1200px;
    height: auto;
    max-height: 600px;
    padding: 100px 0%;
    position: relative;
    min-height: 500px;



}

#sectionFour {

    max-width: 1200px;
    height: auto;
    max-height: 600px;
    margin-top: 200px;
    min-height: 500px;

    position: relative;

}



.blackCard {
    width: 300px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 43px;
    font-weight: bolder;
    background-color: black;
    color: white;
}








@media (max-width: 950px) {


    #sectionTwo {

        padding: 100px 0 0 100px;
        width: 300px;
    }

    #sectionFour {

        /* padding: 0 20px; */
        margin: 0 0 40px 0;

    }

    .blackCard {
        width: 300px;
        height: 200px;

    }


}

@media (max-width: 760px) {
    #sectionTwo #sectionFour {
        padding: 0 50px;
        min-height: none;


    }



    .blackCard {
        width: 250px;
        height: 200px;
        font-size: 23px;
    }


}

@media (max-width: 480px) {
    #sectionTwo #sectionFour {
        padding: 0 30px;
    }

    .blackCard {
        width: 200px;
        height: 200px;
        font-size: 23px;
    }


}







/* ================================ */
#sectioThree {

    /* width: 100%; */
    max-width: 1200px;
    margin: 0 auto;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;
    padding: 50px 200px;

    max-height: 1000px;


}

@media (max-width: 1100px) {
    #sectionTwo {
        height: auto;
        padding: 0 20px;

    }

    #sectioThree {

        padding: 0 20px;



    }

}

.AboutCard {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.AboutCardContent {
    width: 50%;
}

.Empty {
    width: 50%;
    height: 100%;
}


.AboutCardContent h2 {
    font-size: 34px;
    text-decoration: underline;
}

.AboutCardContent p {
    font-size: 24px;
}



.Empty svg {
    height: 400px;
    width: 500px;
}




@media (max-width: 900px) {
    #sectioThree {

        max-width: 1200px;
        margin: 0 auto;
        height: auto;
        padding: 0 30px;


    }

    .AboutCard {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }

    .AboutCardContent {
        width: 100%;
    }

    .Empty {
        display: none;

    }


    .AboutCardContent h2 {
        font-size: 34px;
        text-decoration: underline;
    }

    .AboutCardContent p {
        font-size: 24px;
    }


}

@media (max-width: 600px) {
    #sectioThree {

        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        height: auto;
        padding: 0 30px;


    }

    .AboutCard {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }

    .AboutCardContent {
        width: 100%;
    }

    .Empty {
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;

    }


    .AboutCardContent h2 {
        font-size: 34px;
        text-decoration: underline;
    }

    .AboutCardContent p {
        font-size: 24px;
    }



    .Empty svg {
        height: 300px;
        width: 300px;
    }
}


/* /==================== SECTION 4 */


#ServicesSection {
    height: 100px;

}




.blackCard {
    width: 300px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 43px;
    font-weight: bolder;
    background-color: black;
    color: white;
}

.sectionFiveWrapper {
    height: auto;
    /* background-color: pink ; */
    display: flex;
    justify-content: center;
    align-items: center;

}

.sectionFive {
    padding: 0px;
    max-width: 1200px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    text-align: center;
    position: relative;
    margin: 0 auto;


}

/* For screens with a maximum width of 600px (mobile screens) */
@media (max-width: 600px) {
    .sectionFiveWrapper {
        padding: 0;
    }


}

.sectionFiveHeading {
    font-size: 43px;
    font-weight: bolder;
}



.wrapperForSectionSix {
    /* width: auto; */
    background-color: black;

}

/* Section Six */
#sectionSix {
    margin: 0 auto;
    max-width: 1200px;
    background-color: black;
    height: auto;
    max-height: 900px;
    padding: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

}

@media (max-width: 900px) {
    #sectionSix {
        padding: 5%;
        justify-content: start;
        align-items: center;
        flex-direction: column;

        text-align: center;
    }

    .fullLogoSvg img {
        width: 400px;
        height: auto;
    }

    .wrapperForSectionSix {
        padding: 0%;

    }


}

@media (max-width: 600px) {
    #sectionSix {

        /* padding: 0 5%; */

        justify-content: start;
        align-items: center;
        flex-direction: column;

        text-align: center;

    }

    .fullLogoSvg img {
        width: 400px;
        height: auto;
    }

}

@media (max-width: 400px) {

    .fullLogoSvg img {
        width: 300px;
        height: auto;
    }

}

.sectionSixContent {
    width: 100%;
    color: white;
    font-size: 23px;
}

#sectionSeven {
    padding: 100px 200px 0 200px;
    height: 40vh;
}

/* Section 8================= */

.wrapperForSectionEight {
    /* background-color: pink; */
    /* width: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 0 15% 5% 15%;
}

#sectionEight {
    /* padding: 0 10%; */
    height: auto;
    margin: 0 auto;
    /* background-color: brown; */
    display: flex;
    justify-content: center;
    align-items: start;
    text-align: start;
    flex-direction: column;
    gap: 40px;
    width: auto;
    max-width: 1200px;

}




.FormContent {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: start;
    text-align: center;
    flex-direction: column;
    gap: 20px;
}

.FormContentHeading {
    font-size: 60px;
    width: 100%;
}

.FormContentPara {
    font-size: 18px;
    padding: 0 15%;

}

#form {
    display: flex;
    justify-content: start;
    align-items: center;
    text-align: start;
    flex-direction: column;
    gap: 20px;
    width: 100%;

}

/* #form input[type="text"] {
    width: 100%;
    height: 50px;
    border: 2px solid black ;
    width: 900px;


} */
#form input,
#formTextArea {
    width: 100%;
    height: 50px;
    border: 2px solid black;
    width: 900px;
    padding: 10px;


}

#formTextArea {
    height: 200px;

}

#formSubmitButton {

    width: 100px;
    height: 30px;
    background-color: black;
    color: white;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 10px;
    cursor: pointer;
}

/* Responsive */

@media (max-width: 900px) {


    .wrapperForSectionEight {
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
    }

    #sectionEight {

        justify-content: start;
        align-items: start;
        text-align: start;
        flex-direction: column;
        gap: 40px;
        padding: 0;
    }




    .FormContent {
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: start;
        text-align: start;
        flex-direction: column;
        gap: 20px;
    }

    .FormContentHeading {
        font-size: 60px;
        padding: 0 2%;

    }

    .FormContentPara {
        font-size: 18px;
        padding: 0 2%;

    }

    #form {
        padding: 0;

        display: flex;
        justify-content: start;
        align-items: start;
        text-align: start;
        flex-direction: column;
        gap: 20px;

    }

    #form input,
    #formTextArea {
        width: 700px;
        height: 50px;
        border: 2px solid black;
        padding: 10px;


    }

    #formTextArea {
        height: 200px;

    }

    #formSubmitButton {

        width: 100px;
        height: 30px;
        background-color: black;
        color: white;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-left: 10px;
    }

}

@media (max-width: 600px) {


    .wrapperForSectionEight {
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
    }

    #sectionEight {

        justify-content: start;
        align-items: start;
        text-align: start;
        flex-direction: column;
        gap: 40px;
        padding: 0;
    }




    .FormContent {
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: start;
        text-align: start;
        flex-direction: column;
        gap: 20px;
    }

    .FormContentHeading {
        font-size: 30px;
        padding: 0 2%;

    }

    .FormContentPara {
        font-size: 15px;
        padding: 0 2%;

    }

    #form {
        padding: 0 2%;

        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        gap: 20px;

    }

    #form input,
    #formTextArea {
        width: 300px;
        height: 50px;
        border: 2px solid black;
        padding: 10px;


    }

    #formTextArea {
        height: 200px;

    }

    #formSubmitButton {

        width: 100px;
        height: 30px;
        background-color: black;
        color: white;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-left: 10px;
    }

}























.footerWrapper {
    /* width: 100%; */
    /* width: 100%; */
    /* background-color: #904b4b; */
    background-color: #F0F0F0;

    height: auto;
    padding: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
}


@media (min-width: 1200px) {
    .footer {
        padding: 0 3%;
    }
}


@media (max-width: 600px) {
    .footer {
        padding: 0 3%;
    }
}
@media (max-width: 910px) {
    .footerContent{
        flex-direction: column;
    }
    .footer {
        padding: 0 5%;
    }
}
.footer {

    margin-top: 100px;
    max-width: 1200px;
    height: auto;

    background-color: #F0F0F0;

    display: flex;
    justify-content: start;
    align-items: start;

    flex-direction: column;
    gap: 20px;
    max-width: 1200px;


    /* padding: 5%; */
    margin: 0 auto;
}

.footerContent {
    display: flex;
    justify-content: start;
    align-items: start;

}



.footerContentLeft {
    width: 50%;
    display: flex;
    justify-content: start;
    align-items: start;

    flex-direction: column;
    gap: 20px;
}

.footerContentRight {
    width: 50%;
}

.footerContentHeading {
    font-size: 43px;
    font-weight: 900;
}


.footerLinks {
    display: flex;
    justify-content: start;
    align-items: start;

    list-style: none;
    gap: 50px;
    padding: 0;

}

.footerLinks li {
    text-decoration: none;
}

.footerLinks li a {
    text-decoration: none;


}

.footerRightLinks {
    display: flex;
    justify-content: space-between;
    list-style: none;
    gap: 50px;

    padding: 0;
}

.footerRightLinks li {
    text-decoration: none;
    font-size: 43px;
    font-weight: bolder;
}

.footerRightLinks li a {
    text-decoration: none;
    color: black;
}


@media (max-width : 600px) {








    .footerWrapper {
        padding: 0;
        width: auto;
        padding: 0;
    }





    .footer {
        width: 100vw;

        height: auto;




        background-color: #F0F0F0;

        display: flex;
        justify-content: start;
        align-items: start;

        flex-direction: column;
        gap: 20px;

    }

    .footerContent {
        display: flex;
        justify-content: start;
        align-items: start;
        flex-direction: column;

    }

    .footerContentLeft {
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: start;

        flex-direction: column;
        gap: 20px;
    }

    .footerContentRight {
        width: 100%;
    }

    .footerContentHeading {
        font-size: 43px;
        font-weight: 900;
    }


    .footerLinks {
        display: flex;
        justify-content: start;
        align-items: start;

        list-style: none;
        gap: 50px;
        padding: 0;

    }

    .footerLinks li {
        text-decoration: none;
    }

    .footerLinks li a {
        text-decoration: none;


    }

    .footerRightLinks {
        display: flex;
        justify-content: space-between;
        list-style: none;
        gap: 50px;

        padding: 0;
    }

    .footerRightLinks li {
        text-decoration: none;
        font-size: 43px;
        font-weight: bolder;
    }

    .footerRightLinks li a {
        text-decoration: none;
        color: black;
    }
}


@media (max-width : 900px) {


    .footerWrapper {
        padding: 0;

    }





    footer {

        margin-top: 100px;
        height: auto;
        padding: 100px 200px;
        background-color: #F0F0F0;
        display: flex;
        justify-content: start;
        align-items: start;
        flex-direction: column;
        gap: 20px;

    }

    .footerContent {
        display: flex;
        justify-content: start;
        align-items: start;
        flex-direction: column;
    }

    .footerContentLeft {
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: start;

        flex-direction: column;
        gap: 20px;
    }

    .footerContentRight {
        width: 100%;
    }

    .footerContentHeading {
        font-size: 43px;
        font-weight: 900;
    }


    .footerLinks {
        display: flex;
        justify-content: start;
        align-items: start;

        list-style: none;
        gap: 50px;
        padding: 0;

    }

    .footerLinks li {
        text-decoration: none;
    }

    .footerLinks li a {
        text-decoration: none;


    }

    .footerRightLinks {
        display: flex;
        justify-content: space-between;
        list-style: none;
        gap: 50px;

        padding: 0;
    }

    .footerRightLinks li {
        text-decoration: none;
        font-size: px;
        font-weight: bolder;
    }

    .footerRightLinks li a {
        text-decoration: none;
        color: black;
    }
}

@media (max-width: 400px) {

    .footerContentHeading {
        font-size: 13px;
        font-weight: 900;
    }

}

@media (max-width : 900px) {








    .footerWrapper {
        padding: 0;
    }





    footer {

        margin-top: 100px;

        height: auto;



        padding: 100px 200px;

        background-color: #F0F0F0;

        display: flex;
        justify-content: start;
        align-items: start;

        flex-direction: column;
        gap: 20px;

    }

    .footerContent {
        display: flex;
        justify-content: start;
        align-items: start;
        flex-direction: column;

    }

    .footerContentLeft {
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: start;

        flex-direction: column;
        gap: 20px;
    }

    .footerContentRight {
        width: 100%;
    }

    .footerContentHeading {
        font-size: 43px;
        font-weight: 900;
    }


    .footerLinks {
        display: flex;
        justify-content: start;
        align-items: start;

        list-style: none;
        gap: 50px;
        padding: 0;

    }

    .footerLinks li {
        text-decoration: none;
    }

    .footerLinks li a {
        text-decoration: none;


    }

    .footerRightLinks {
        display: flex;
        justify-content: space-between;
        list-style: none;
        gap: 50px;

        padding: 0;
    }

    .footerRightLinks li {
        text-decoration: none;
        font-size: 20px;
        font-weight: bolder;
    }

    .footerRightLinks li a {
        text-decoration: none;
        color: black;
    }
}


