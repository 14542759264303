@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");



.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar {
  height: 60px;
  position: relative;
  position: fixed;
  width:100% ;
  z-index: 10;
  background-color: white;

  
}

.menu-icon {
  display: none;
}
ul{
    display: flex;
    
    align-items: center;
    
}

#ContactUsButton{
    width: 100px;
    height: 30px;
    background-color: black;
    color: white !important;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    text-align: center;
    margin-left: 10px;
}
#ContactUsButton a{
    color: white !important;
    margin: 3px 0 0 0;
}
#ContactUsButton {
    margin: 2px 0 0 0;
}
.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 40px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #2f234f;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

@media (max-width: 900) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: white;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
    height: 100vh;
    padding: 10%;

  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;

    display: flex;
    /* justify-self: end; */
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    
  }
  .nav-elements ul li a{
    width: 100px;
  }
  
#ContactUsButton{
    margin-left: 0;
    width: 100%;
    display: flex;
    

}
}
